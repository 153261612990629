<template>
  <v-dialog
    max-width="900px"
    v-model="model"
  >
    <v-card title>
      <v-toolbar flat>
        <v-toolbar-title>
          Удалить
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        class="px-5"
      >
        Вы уверены что хотите удалить?
      </v-card-text>
      <v-card-actions
        class="d-flex px-5 justify-space-between"
      >
        <div>
          <v-btn @click="model = false" text>Отмена</v-btn>
        </div>
        <div>
          <v-btn color="error" text>В корзину</v-btn>
          <v-btn color="error" @click="$emit('success')" class="ml-2">Удалить</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  name: 'DeleteFolderDialog',
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>